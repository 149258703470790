import "./style.scss";
import "vite/modulepreload-polyfill";

const components = [
    ".accordion",
    ".footer",
    ".header",
    ".location-filter",
    ".nav",
    ".slider",
    ".forms",
    ".numbers-facts",
    ".video",
    ".location-search",
    "[data-lightbox]",
    ".teaser-slider",
    ".dropdown",
    ".tx-sf-banners", // Modal
    ".stage-slider",
    ".teaser-tabs",
    ".certificate-slider"
];

async function init() {
    console.log("[initalized] main.js");
    // Dynamic importing of the component js
    for (let i = 0; i < components.length; i++) {
        switch (components[i]) {
            case ".accordion":
                if (document.querySelector(components[i])) {
                    console.log("[detected] accordion");
                    const accordion = await import(
                        "./src/components/accordion/accordion.js"
                    );
                    accordion.init();
                }
                break;
            case ".header":
                if (document.querySelector(components[i])) {
                    console.log("[detected] header");
                    const header = await import(
                        "./src/components/header/header.js"
                    );
                    header.init();
                }
                break;
            case ".footer":
                if (document.querySelector(components[i])) {
                    console.log("[detected] footer");
                    const footer = await import(
                        "./src/components/footer/footer.js"
                    );
                    footer.init();
                }
                break;
            case ".location-filter":
                if (document.querySelector(components[i])) {
                    console.log("[detected] location-filter");
                    const locationFilter = await import(
                        "./src/components/location-filter/location-filter.jsx"
                    );
                    locationFilter.init();
                }
                break;
            case ".nav":
                if (document.querySelector(components[i])) {
                    console.log("[detected] navigation");
                    const nav = await import("./src/components/nav/nav.js");
                    nav.init();
                }
                break;
            case ".slider":
                if (document.querySelector(components[i])) {
                    console.log("[detected] slider");
                    const slider = await import(
                        "./src/components/slider/slider.js"
                    );
                    slider.init();
                }
            case ".forms":
                if (document.querySelector(components[i])) {
                    console.log("[detected] forms");
                    const forms = await import(
                        "./src/components/forms/forms.js"
                    );
                    forms.init();
                }
                break;
            case ".numbers-facts":
                if (document.querySelector(components[i])) {
                    console.log("[detected] numbers-facts");
                    const forms = await import(
                        "./src/components/numbers-facts/numbers-facts.js"
                    );
                    forms.init();
                }
                break;
            case ".video":
                if (document.querySelector(components[i])) {
                    console.log("[detected] forms");
                    const forms = await import(
                        "./src/components/video/video.js"
                    );
                    forms.init();
                }
                break;
            case ".location-search":
                if (document.querySelector(components[i])) {
                    console.log("[detected] location-search");
                    const locationSearch = await import(
                        "./src/components/location-search/location-search.ts"
                    );
                    locationSearch.init();
                }
                break;
            case "[data-lightbox]":
                if (document.querySelector(components[i])) {
                    console.log("[detected] lightbox");
                    const lightbox = await import(
                        "./src/components/lightbox/lightbox.js"
                    );
                    lightbox.init();
                }
                break;
            case ".dropdown":
                if (document.querySelector(components[i])) {
                    console.log("[detected] dropdown");
                    const dropdown = await import(
                        "./src/components/dropdown/dropdown.js"
                    );
                    dropdown.init();
                }
                break;
            case ".tx-sf-banners":
                if (document.querySelector(components[i])) {
                    console.log("[detected] modal");
                    const modal = await import(
                        "./src/components/modal/modal.js"
                    );
                    modal.init();
                }
                break;
            case ".stage-slider":
                if (document.querySelector(components[i])) {
                    console.log("[detected] stage-slider");
                    const stageSlider = await import(
                        "./src/components/stage-slider/stage-slider.js"
                    );
                    stageSlider.init();
                }
                break;
            case ".teaser-tabs": {
                if (document.querySelector(components[i])) {
                    console.log("[detected] teaser-tabs");
                    const teaserTabs = await import(
                        "./src/components/teaser-tabs/teaser-tabs.js"
                    );
                    teaserTabs.init();
                }
                break;
            }
            case ".certificate-slider": {
                if (document.querySelector(components[i])) {
                    console.log("[detected] certificate-slider");
                    const certificateSlider = await import(
                        "./src/components/certificate-slider/certificate-slider.js"
                    );
                    certificateSlider.init();
                }
                break;
            }
            default:
                break;
        }
    }

    // If the the page is served on gitlab pages, expand all links with the base path
    // variable
    if (Location.hostname === "pages-brandung.de") {
        import("./src/helpers/base-path-helper.js").then((module) =>
            module.setBasePathInTemplates()
        );
    }
}

init();
